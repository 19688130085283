<template>
  <div class="home-box">
    <div class="m-title2">
      <div class="t">
        <div class="lan">
          <!-- PolySmartChain -->
          {{ $t("home.box2Tl") }}
        </div>
        <!-- 为元宇宙构建 -->
        {{ $t("home.box2Tr") }}
      </div>
    </div>
    <div class="box">
      <!-- PSC 构建原理 -->
      <div class="t">
        {{ $t("home.box2ST") }}
      </div>
      <div class="p">
        <!--  -->
        <!-- PolySmartChain是一个快速、高吞吐量的开源智能合约平台，它结合了突破性的技术，为去中心化的应用程序、系统和社会提供无与伦比的安全性和可持续性。 -->
        {{ $t("home.box2P") }}
      </div>
      <div class="m-but2" @click="uti.goPath(store.link.home.box2But)">
        <!-- 立即探索 -->
        {{ $t("home.box2But") }}
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.home-box {
  padding: (31 / 3.75vw) 0;
  text-align: center;
  // height: (271 / 3.75vw);
  .box {
    padding: (32 / 3.75vw) (14 / 3.75vw) (61 / 3.75vw);
    margin-top: (20 / 3.75vw);
    background-image: url("../../assets/images/v4/home/m/box2BG.png");
    background-size: 100% 100%;

    // background-color: pink;
    .t {
      font-size: (16 / 3.75vw);
    }
    .p {
      font-size: (12 / 3.75vw);
      color: #999999;
      margin-top: (12 / 3.75vw);
      line-height: (23 / 3.75vw);
    }
    .m-but2 {
      margin-top: (56 / 3.75vw);
    }
  }
}
</style>
