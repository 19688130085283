<template>
  <div class="home-box">
    <div class="m-title2">
      <div class="t">
        <!-- PolySmartChain 社区 -->
        {{ $t("home.box8T") }}
      </div>
    </div>
    <div class="box">
      <div class="p">
        <!-- PSC是一个全球性的去中心化网络，拥有来自世界各地的验证者和社区成员。
          加入 Discord 上的讨论并及时了解最新消息和公告。 -->
        {{ $t("home.box8ST") }}
      </div>
      <div class="ul">
        <div class="item" @click="uti.goPath(store.link.home.box8I1)">
          <img src="../../assets/images/v4/home/Twitter.png" alt="" />
          <div class="text">
            <!-- 关注最新消息 -->
            {{ $t("home.box8I1") }}
          </div>
        </div>
        <div class="item" @click="uti.goPath(store.link.home.box8I2)">
          <img src="../../assets/images/v4/home/Discord.png" alt="" />
          <div class="text">
            <!-- 参与其中  -->
            {{ $t("home.box8I2") }}
          </div>
        </div>
        <div class="item" @click="uti.goPath(store.link.home.box8I3)">
          <img src="../../assets/images/v4/home/Medium.png" alt="" />
          <div class="text">
            <!-- 检查进度 -->
            {{ $t("home.box8I3") }}
          </div>
        </div>
        <div class="item" @click="uti.goPath(store.link.home.box8I4)">
          <img src="../../assets/images/v4/home/Telegram.png" alt="" />
          <div class="text">
            <!-- 加入社区 -->
            {{ $t("home.box8I4") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.home-box {
  // margin-bottom: 250px;
  background-image: url("../../assets/images/v4/home/box8Bg.png");
  background-repeat: no-repeat;
  padding-top: (29 / 3.75vw);
  box-sizing: border-box;
  text-align: center;
  .box {
    // background-color: pink;
    padding: 0 (14 / 3.75vw);
    margin-top: 56px;
    height: 366px;
    .p {
      font-size: (12.67 / 3.75vw);
      line-height: (25 / 3.75vw);
      font-weight: 500;
      color: #999999;
      margin: 0 auto;
      text-align: center;
    }
    .ul {
      margin-top: (38 / 3.75vw);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      // margin-top: 108px;

      .item {
        width: (166.67 / 3.75vw);
        height: (109.03 / 3.75vw);
        background: #edf6fe;
        box-shadow: (2.03 / 3.75vw) (2.03 / 3.75vw) (6.03 / 3.75vw) 0px
          rgba(167, 201, 237, 0.5);
        text-align: center;
        margin-bottom: (12.03 / 3.75vw);
        transition: 0.3s;
        img {
          margin-top: (25.67 / 3.75vw);
          width: (88.67 / 3.75vw);
        }
        .text {
          margin-top: (18 / 3.75vw);
          font-size: (12.67 / 3.75vw);
          font-weight: bold;
          color: #333333;
        }
      }
    }
  }
}
</style>
