<template>
  <div class="home-box1">
    <!-- 我们正在进入元宇宙 -->
    <div class="box1T">{{ $t('home.box1T') }}</div>
    <!-- PolySmartChain让它变得更好! -->
    <div class="box1SubT">{{ $t('home.box1ST') }}!</div>
    <!-- 开始使用 -->
    <div class="m-but" @click="uti.goPath(store.link.home.box1But)">
      {{ $t('home.box1But') }}
    </div>
    <div class="text">
      <!-- PSC是由社区驱动的技术，为加密货币PSC和成千上万的去中心化应用程序提供动力。 -->
      {{ $t('home.box1FT') }}
    </div>
    <div class="ft">
      <div class="item">
        <!-- 出块时间 -->
        <div class="li">{{ $t('home.box1Ft1') }}</div>
        <div class="li rr">3s</div>
      </div>
      <div class="item">
        <!-- tps -->
        <div class="li">{{ $t('home.box1Ft2') }}</div>
        <div class="li rr">2,000+</div>
      </div>
      <div class="item">
        <!-- 价格 -->
        <div class="li">{{ $t('home.box1Ft3') }}</div>
        <div class="li rr">{{ store.homePrice }}</div>
      </div>
      <div class="item">
        <!-- Staking 收益率 -->

        <div class="li">{{ $t('home.box1Ft4') }}</div>
        <div class="li rr">{{ store.caculate }}%</div>
      </div>
      <div class="item">
        <!-- Staking 销毁 -->

        <div class="li">Destroy</div>
        <div class="li rr">{{ store.DestroyNumber }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from '../../store';
import abi from '../../assets/abi';
import ercabi from '../../assets/erc20Abi';
import Decimal from 'decimal.js';

export default {
  async mounted() {
    // 生成web3
    this.web3 = new this.$web3(
      'https://seed0.PolySmartChain.com' || 'https://seed1.PolySmartChain.com'
    );
    await this.getCakeRewardPerBlock();
    await this.getTotalValue();
    await this.balanceof();
    //计算收益率
    this.calculate();
    this.getPrice();
    this.getWPSCDestroyNumber();
  },
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      web3: '',
      cakeRewardPerBlock: '',
      poolInfo: '',
      totalValue: '',
      balance: '',
      totalSupply: '',
      price: '',
      masterChef: window.wl.masterChef,
      puLp: window.wl.puLp,
      weth: window.wl.weth,
    };
  },
  methods: {
    // 获取cakeRewardPerBlock 和 poolInfo
    async getCakeRewardPerBlock() {
      let myContract = new this.web3.eth.Contract(abi, this.masterChef);
      this.cakeRewardPerBlock = await myContract.methods.eggPerBlock().call();
      let { allocPoint } = await myContract.methods.poolInfo(0).call();
      this.poolInfo = allocPoint;
      // console.log('cakeRewardPerBlock:' + this.cakeRewardPerBlock);
      // console.log('poolInfo:' + this.poolInfo);
    },
    // 获取lptoken数量
    async getTotalValue() {
      let myContract = new this.web3.eth.Contract(ercabi, this.weth);
      this.totalValue = await myContract.methods.balanceOf(this.puLp).call();
      // console.log('totalValue:' + this.totalValue);
    },
    // 获取balance 和 totalSupply
    async balanceof() {
      let myContract = new this.web3.eth.Contract(ercabi, this.puLp);
      this.balance = await myContract.methods.balanceOf(this.masterChef).call();
      // console.log(this.balance);
      this.totalSupply = await myContract.methods.totalSupply().call();
      // console.log(this.totalSupply);
      // console.log('totalValue:' + this.totalValue);
    },
    // 计算收益率
    calculate() {
      /**
       *            cakeRewardPerBlock * 6307200 * (poolInfo / 12000)
       *  apy  =   ——————————————————————————————————————————————————————
       *                  totalValue / ( balance / totalSupply )
       */
      if (
        !this.cakeRewardPerBlock ||
        !this.totalValue ||
        !this.poolInfo ||
        !this.balance ||
        !this.totalSupply
      ) {
        return;
      }
      let a = new Decimal(6307200);
      let b = new Decimal(12000);
      let cakeRewardPerBlock = new Decimal(this.cakeRewardPerBlock);
      let totalValue = new Decimal(this.totalValue);
      let poolInfo = new Decimal(this.poolInfo);
      let balance = new Decimal(this.balance);
      let totalSupply = new Decimal(this.totalSupply);

      let apy = cakeRewardPerBlock
        .mul(a.mul(poolInfo.div(b)))
        .div(totalValue.div(totalSupply.div(balance)))
        .mul(100)
        .toFixed(2);
      this.store.caculate = apy;
    },
    // 获取psc价格
    async getPrice() {
      let { data } = await this.$http.get(window.wl.pscPriceURL);
      // this.price = data.psc_price.substr(1);
      this.store.homePrice = data.psc_price.substr(1);
      // console.log(this.price);
      localStorage.setItem('homePrice', data.psc_price.substr(1));
    },
    // 获取wpsc销毁量
    async getWPSCDestroyNumber() {
      let myContract = new this.web3.eth.Contract(
        ercabi,
        window.wl.pscDestroyAddr
      );
      let destroy = await myContract.methods
        .balanceOf('0x0000000000000000000000000000000000000000')
        .call();
      this.store.DestroyNumber = this.uti.addThousand(this.uti.addDec(destroy));
    },
  },
};
</script>

<style lang="less" scoped>
.home-box1 {
  color: #fff;
  background-size: 100% 100%;
  background-image: url('../../assets/images/v4/home/m/box1Bg.png');
  text-align: center;
  padding: (118 / 3.75vw) (14 / 3.75vw) 0;
  .box1T {
    font-size: (30 / 3.75vw);
  }
  .box1SubT {
    font-size: (13.33 / 3.75vw);
    margin-top: (11 / 3.75vw);
  }
  .m-but {
    margin-top: (38 / 3.75vw);
    background-color: #fff;
    border: none;
  }
  .text {
    margin-top: (36 / 3.75vw);
    font-size: (12 / 3.75vw);
  }
  .ft {
    margin-top: (26 / 3.75vw);
    .item {
      width: (273.33 / 3.75vw);
      height: (41.67 / 3.75vw);
      margin: (7.33 / 3.75vw) auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-image: url('../../assets/images/v4/home/m/box1ItemBg.png');
      background-size: 100% 100%;
      .li {
        width: 50%;
        font-size: (12 / 3.75vw);
        font-weight: Regular;
      }
      .rr {
        font-weight: Bold;
        font-size: (18 / 3.75vw);
      }
    }
  }
}
</style>
