<template>
  <div class="home-box">
    <div class="m-title2">
      <div class="t">
        <!-- PSC Ecosystem -->
        {{ $t('home.box6T') }}
      </div>
    </div>
    <div class="boxBox" ref="boxBox">
      <div class="box" ref="box">
        <div
          class="m-item2"
          v-for="(item, index) in $t('home.box6ItemList')"
          :key="index"
        >
          <div class="img">
            <img
              :src="require(`../../assets/images/v4/home/box6${index + 1}.png`)"
              alt=""
            />
          </div>
          <div class="t">{{ item.title }}</div>
          <div class="link"></div>
          <div class="p">
            {{ item.text }}
          </div>
          <div
            class="more"
            @click="uti.goPath(store.link.home.box6List[index])"
          >
            {{ item.ft }}
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-arrowRight"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="dianBox">
      <div :class="{ act: act == 1 }" class="dian"></div>
      <div :class="{ act: act == 2 }" class="dian"></div>
      <div :class="{ act: act == 3 }" class="dian"></div>
      <div :class="{ act: act == 4 }" class="dian"></div>
      <div :class="{ act: act == 5 }" class="dian"></div>
      <div :class="{ act: act == 6 }" class="dian"></div>
      <div :class="{ act: act == 7 }" class="dian"></div>
      <div :class="{ act: act == 8 }" class="dian"></div>
    </div>
  </div>
</template>

<script>
import Store from '../../store';
export default {
  mounted() {
    this.setTm();
    // vant.Toast("提示");
  },
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      act: 3,
      tm: '',
    };
  },
  methods: {
    getLeftNumber() {
      let left = this.$refs.boxBox.scrollLeft;
      if (left < 250) {
        return 1;
      } else if (left < 450) {
        return 2;
      } else if (left < 840) {
        return 3;
      } else if (left < 1100) {
        return 4;
      } else if (left < 1200) {
        return 5;
      } else if (left < 1570) {
        return 6;
      } else if (left < 1640) {
        return 7;
      } else {
        return 8;
      }
    },
    setTm() {
      this.tm = setInterval(() => {
        this.act = this.getLeftNumber();
      }, 30);
    },
  },
  beforeDestroy() {
    clearInterval(this.tm);
  },
};
</script>

<style lang="less" scoped>
.home-box {
  background-repeat: no-repeat;
  background-position: 0 60%, right bottom;
  padding: (30 / 3.75vw) 0 (34 / 3.75vw);
  box-sizing: border-box;
  .boxBox {
    width: 100vw;
    overflow: auto;
    padding: 0 (14 / 3.75vw);
    box-sizing: border-box;
    .box {
      margin-top: (17 / 3.75vw);
      // background-color: pink;
      width: (2165 / 3.75vw);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .dianBox {
    margin-top: (14 / 3.75vw);
    display: flex;
    justify-content: center;
    .dian {
      width: (16 / 3.75vw);
      height: (3.33 / 3.75vw);
      margin: 0 (4 / 3.75vw);
      border-radius: (1.67 / 3.75vw);
      background-color: #cccccc;
    }
    .act {
      width: (47.67 / 3.75vw);
      background: linear-gradient(-90deg, #10c1fc, #3f65f9);
    }
  }
}
</style>
