<template>
  <div class="home-box">
    <div class="m-title3">
      <div class="t">
        <!-- PolySmartChain 有何优势 -->
        {{ $t("home.box5T") }}
      </div>
    </div>
    <div class="box1">
      <div class="box11">
        <div class="t">
          <!-- 建立在 PSC 上 -->
          {{ $t("home.box5LT") }}
        </div>
        <div class="p">
          <!-- 使用 Solidity 和您已经熟悉的工具开始在 PSC 上构建 -->
          {{ $t("home.box5LP") }}
        </div>
        <div class="but" @click="uti.goPath(store.link.home.box5But)">
          <!-- 阅读文档 -->
          {{ $t("home.box5LBut") }}
        </div>
      </div>
    </div>
    <div class="box2">
      <div
        class="m-item1"
        v-for="(item, index) in $t('home.box5ItemList')"
        :key="index"
      >
        <div class="img">
          <img
            :src="require(`../../assets/images/v4/home/box5${index + 1}.png`)"
            alt=""
          />
        </div>
        <div class="t">{{ item.title }}</div>
        <div class="link"></div>
        <div class="p">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.home-box {
  // height: 100vh;
  // height: 1220px;
  background-image: url("../../assets/images/v4/home/m/box5Bg.png");
  background-size: 100% 100%;
  margin-top: (30 / 3.75vw);
  padding-bottom: (20 / 3.75vw);
  padding-top: (51 / 3.75vw);

  box-sizing: border-box;
  .box1 {
    // height: 328px;
    // background-color: pink;
    margin-top: (46 / 3.75vw);
    padding: 0 (14 / 3.75vw);
    text-align: center;
    display: flex;
    .box11 {
      flex: 1;
      .t {
        font-size: (16 / 3.75vw);
        font-weight: 500;
        color: #ffffff;
      }
      .p {
        font-size: (12 / 3.75vw);
        line-height: (25 / 3.75vw);
        font-weight: 400;
        color: #ffffff;
        margin-top: (22 / 3.75vw);
      }
      .but {
        display: inline-block;
        padding: 0 (27 / 3.75vw);
        height: (40 / 3.75vw);
        line-height: (40 / 3.75vw);
        background: rgba(255, 255, 255, 0.91);
        box-shadow: 0px (1.67 / 3.75vw) (6.67 / 3.75vw) 0px
          rgba(26, 77, 143, 0.5);
        border-radius: (20 / 3.75vw);
        margin-top: (46 / 3.75vw);
        font-size: (16 / 3.75vw);
        color: #000;
        a {
          color: #000;
        }
      }
    }
  }
  .box2 {
    display: flex;
    flex-wrap: wrap;
    padding: 0 (14 / 3.75vw);
    justify-content: space-between;
    margin-top: (54 / 3.75vw);
  }
}
</style>
